import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Otherservice from "../components/otherservice";
import { IoArrowUpOutline } from "react-icons/io5";
import { AnimatePresence } from "framer-motion";
import Form from "../components/form";
import MetaTag from "../components/metaTag";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import { dailycleaningSEO } from "../components/data/seo";
import {
  container,
  preview,
  stock,
  option,
  price,
  list,
  background,
  title,
  description,
  vector,
  otherservices,
  btn,
} from "../styles/pages/service.module.scss";

function Dailycleaning() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <MetaTag data={dailycleaningSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
      >
        <div className={container}>
          <div className={preview}>
            <h2 className={title}>Повседневная уборка квартиры</h2>
            <p className={description}>от 2700₽</p>
            <StaticImage
              src="../images/services/6.jpg"
              alt="обложка для Повседневной уборки квартиры"
              className={background}
            />
          </div>
          <div className={stock}>
            <h3 className={title}>Закажите клининг по акции!</h3>
            <p>
              При заказе любого типа помещения - скидка до 30% на услуги
              химчистки мягкой мебели
            </p>
            <button className={btn} onClick={() => setOpenForm(true)}>
              Заказать уборку <IoArrowUpOutline />
            </button>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </div>
          <div
            className={option}
            style={{
              background: "linear-gradient(180deg, #F17F29 0%, #197BBD 100%)",
            }}
          >
            <h2 className={title}>Что входит:</h2>
            <p className={description}>
              Дополнительные позиции обсуждаются индивидуально
            </p>
            <p className={list}>
              - Сухая и влажная уборка полов и плинтусов
              <br />
              - Сухая чистка ковров и мебели
              <br />
              - Очистка радиаторов и труб, полотенцесушителей, выключателей и
              розеток
              <br />
              - Очистка светильников, мебели снаружи, зеркал и стеклянных
              поверхностей
              <br />
              - Мойка подоконников, люстр, простой форм
              <br />
              - Протираем плиту, СВЧ (снаружи)
              <br />
              - Влажная уборка дверей и дверных проемов, ручек
              <br />
              - Удаление ржавчины в легкодоступных местах сан.узла
              <br />- Очистка и дезинфекция всей сантехники и смесителей
            </p>
          </div>
          <div
            className={price}
            style={{
              background:
                "linear-gradient(3.2deg, #DEA10B -22.42%, #197BBD 100%)",
            }}
          >
            <h2 className={title}>Прайс</h2>
            <p className={description}>
              Для расчета более точной цены, оставьте заявку
            </p>
            <p className={list}>
              45 кв.м / от 2700₽
              <br />
              65 кв.м / от 3800₽
              <br />
              85 кв.м / от 4700₽
              <br />
              125 кв.м - 160 кв.м / от 5300₽
              <br />
              более 160 кв.м / индивидуально
            </p>
            <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
          </div>
        </div>
        <h3 className={otherservices}>Прочие услуги:</h3>
        <Otherservice hide={5} />
        <AnimatePresence>
          {openForm && (
            <Form
              service="повседневную уборку квартиры"
              closeForm={() => setOpenForm(false)}
            />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
}

export default Dailycleaning;
